<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      {{ titleTranslateKey | translate }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <div class="section">
        <div></div>
        <div class="content">
          <mat-tab-group #tabgroup (selectedIndexChange)="changeIndexTab(tabgroup, $event)">
            <!-- if you change index of tabs, you need to change the index in the function onUploadFileSuccess -->
            <mat-tab>
              <ng-template mat-tab-label> Files</ng-template>
              <app-file-upload
                (onUploadSuccess)="onUploadFileSuccess($event)"
                [attachment]=""
                [column]="column"
                [disabled]="disabled"
                [icon]="'hol-icon-file'"
                [label]="'COMMON.FILE' | translate"
                (isLoading)="startUpload($event)"
              >
              </app-file-upload>
            </mat-tab>
            <mat-tab [disabled]="!displayLinkTab ? 'true' : 'false'">
              <ng-template mat-tab-label>Link</ng-template>
              <mat-form-field>
                <input
                  (ngModelChange)="checkIfHttps($event)"
                  [(ngModel)]="tmpFile.url"
                  matInput
                  pattern="^(https://).*"
                  placeholder="{{ 'COMMON.LINK_PLACEHOLDER' | translate }}"
                  type="url"
                />
                <mat-error *ngIf="tmpFile.url && !tmpFile.url.startsWith('https://')">{{ 'COMMON.LINK_EXPLAIN' | translate }} </mat-error>
                <span class="error"></span>
              </mat-form-field>
            </mat-tab>
            <mat-tab [disabled]="!displayImageTab ? 'true' : 'false'">
              <ng-template mat-tab-label> Image</ng-template>
              <app-file-upload
                (onUploadSuccess)="onUploadFileSuccess($event)"
                [accept]="'image/x-png,image/gif,image/jpeg'"
                [attachment]="tmpFile"
                [column]="column"
                [disabled]="disabled"
                [icon]="'hol-icon-picture'"
                [label]="'COMMON.IMAGE' | translate"
              >
              </app-file-upload>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="section">
        <div></div>
        <div class="content">
          <mat-form-field>
            <input [(ngModel)]="tmpFile.title" matInput placeholder="{{ placeholderTranslateKey | translate }}" type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-bar">
      <button (click)="closeModal()" class="action action-refuse">
        <span class="material-symbols-outlined">cancel</span>
        {{ 'COMMON.CANCEL' | translate | uppercase }}
      </button>
      <button (click)="saveAttachment()" *ngIf="!context || !context.task" [disabled]="saveButtonIsDisable" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <!-- <span *ngIf="isEdition">{{ 'COMMON.EDIT' | translate | uppercase }}</span>-->
        <span>{{ 'COMMON.ADD' | translate | uppercase }}</span>
      </button>
      <button (click)="saveAttachment()" *ngIf="context && context.task" [disabled]="saveButtonIsDisable" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span *ngIf="isEdition">{{ 'COMMON.EDIT' | translate | uppercase }}</span>
        <span *ngIf="!isEdition">{{ 'COMMON.ADD' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
