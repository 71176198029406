import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { HolUser } from 'src/app/common/models/hol-user.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { BaseComponent } from 'src/app/common/components/base/base.component';
import { FormIoCredentials } from 'src/app/common/services/options.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { cloneDeep, uniq } from 'lodash';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { nextInfoType } from 'src/app/common/components/hol-next-info/hol-next-info.component';

import { ErpCrisisTask, ErpVisiblityStatus } from '../../../erp/models/erp-crisisTask';
import { ModalsService } from '../../../erp/services/modals.service';
import { HolHistoryLog } from '../../models/hol-history-log.model';
import { HolNotifyFunction } from '../../models/hol-notification.model';
import { HolTask } from '../../models/hol-task';
import { HistoryService } from '../../services/history.service';
import { TasksService } from '../../services/tasks.service';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('editLabel', { static: false }) editLabel: TemplateRef<any>;
  @Input() formIoCredentials: FormIoCredentials;
  @Input() taskHistoryLogs: HolHistoryLog[];
  @Input() aclLimit: Parse.ACL;
  @Input() isHolder: boolean;
  @Input() isInErdTeam: boolean;
  @Input() functionTitle: string;
  @Input() userHolder: HolUser;
  @Input() isReadOnly = false;
  initLabelName: string;
  outputDataLabelPlaceHolder: string;
  defaultOutputDataLabelPlaceHolder: string;
  notifications: HolNotification[] = [];
  task: HolTask;
  erpTask: ErpCrisisTask;
  context;
  isSaving = false;
  notifyFunction: HolNotifyFunction = { sendByMail: false, sendBySms: false };
  functionsUserForCrisis = undefined;
  tags: HolTag[];
  countRoles = 0;
  form: FormGroup;
  attachmentControl: FormControl;
  statusControl: FormControl;
  commentControl: FormControl;
  hackShowToggleButton: boolean;
  currentVisibility: ErpVisiblityStatus;
  showSection2 = false;
  showSection3 = false;
  isTaskStatusChanged = false;
  isVisibleNotifInfo = true;
  public fb: FormBuilder = new FormBuilder();
  formLabelInput: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
  });
  public isOutputDataLabelDefined = false;
  private _task: BehaviorSubject<HolTask> = new BehaviorSubject<HolTask>(undefined);
  private currentUser: HolUser;
  private logSubscription: Subscription;
  private taskSubscription: Subscription;
  private rolesNotifsSubscription: Subscription;

  constructor(
    @Inject('$state') private $state,
    private tasksService: TasksService,
    private translate: TranslatePipe,
    public moduleConfig: ModuleConfigService,
    private rolesService: RolesService,
    private notificationsService: NotificationsService,
    private historyService: HistoryService,
    private commonStoreManager: CommonStoreManager,
    private modalsService: ModalsService,
    private dialog: MatDialog,
  ) {
    super();
  }

  get taskInput() {
    return this._task.getValue();
  }

  @Input()
  set taskInput(value) {
    this._task.next(value);
  }

  get hasFormIo(): boolean {
    return this.formIoCredentials && this.task && this.task.formIoFormRef && this.task.formIoFormRef.length > 0;
  }

  ngOnInit() {
    this.notifyFunction.functionTitle = this.functionTitle;

    this.context = {
      module: this.moduleConfig.config.moduleName,
      category: 'CRISIS_TASK',
      htmlTitle: '',
      htmlDate: new Date(),
      htmlTemplate: 'A',
      task: this.task,
      initLabelName: this.initLabelName,
    };

    this.taskSubscription = this._task.pipe(take(1)).subscribe(task => {
      if (!task) {
        return;
      }
      if (task.status === 'TODO') {
        delete task.status;
      }

      this.task = cloneDeep(task);
      this.initLabelName = cloneDeep(this.task.outputDataLabel);
      this.currentVisibility = this.task.customVisibleBy || this.task.visibleBy;

      this.initForm();
      this.isOutputDataLabelDefined = this.task.outputDataLabel.trim().length > 0;
      if (task instanceof ErpCrisisTask) {
        this.erpTask = this.task as ErpCrisisTask;
      }
      this.context = {
        module: this.moduleConfig.config.moduleName,
        category: 'CRISIS_TASK',
        htmlTitle: this.task.taskTitle,
        htmlDate: this.task.createdBy.objectId ? this.task.createdAt : new Date(),
        htmlTemplate: 'A',
        task: this.task,
        initLabelName: this.initLabelName,
      };
      this.tags = this.task.tags || [];
    });
    this.logSubscription = this.historyService.logs.pipe(filter(l => l && l.type === 'holTask')).subscribe(log => {
      this.taskHistoryLogs.unshift(log);
    });
    this.defaultOutputDataLabelPlaceHolder =
      (this.erpTask && this.erpTask.outputDataLabel) ||
      this.translate.transform(this.moduleConfig.config.translateKey + '.TASKS.OUTPUT_DATA_LABEL_PLACEHOLDER');
    this.outputDataLabelPlaceHolder = this.defaultOutputDataLabelPlaceHolder;

    this.rolesNotifsSubscription = combineLatest([
      this.rolesService.getUserCompanyRolesByUniverse(this.moduleConfig.config.moduleName.toUpperCase()),
      this.notificationsService.getAll(),
      this.commonStoreManager.currentUser,
    ]).subscribe(([roles, notifs, user]) => {
      this.currentUser = user;

      if (roles && notifs) {
        this.countRoles = roles.length;
        const availableWriteCompanies = uniq(roles.filter(role => role.userWriteRoles.length).map(role => role.company));
        const existingNotifs = this.notifications || [];
        this.notifications = notifs
          .filter(notif => {
            const availableCompanies = notif.companies.filter(c => availableWriteCompanies.indexOf(c) > -1);
            if (!notif.companies || !notif.companies.length || availableCompanies.length > 0) {
              return notif;
            }
          })
          .map(notif => {
            // As we are inside a subscription, this piece of code may be triggered by a polling update
            // notif.sendBySms and sendByMail fields are not retrieved from database, so to avoid lose changes
            // we have to recover the existing user input (const existingNotifs = this.notifications || []) before re-assigning it again
            const existingNotif = existingNotifs.find(n => n.objectId === notif.objectId);
            if (existingNotif) {
              notif.sendBySms = existingNotif.sendBySms;
              notif.sendByMail = existingNotif.sendByMail;
            } else {
              notif.sendBySms = false;
              notif.sendByMail = false;
            }
            notif.readOnly = !this.isHolder || this.task.readOnly;
            return notif;
          })
          .sort();
      }
    });
  }

  closeShowNotifyInfo() {
    this.isVisibleNotifInfo = false;
  }

  openFormLink() {
    window.open(this.task.attachments.files[0].url, '_blank');
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.task.nextInfoTime = nextInfo.nextInfoTime;
    this.task.nextInfoDone = nextInfo.nextInfoDone;
    this.saveTask('UPDATE_TASK_NEXT_INFO');
  }

  public goBack(): void {
    this.$state.go('app.' + this.moduleConfig.config.rootPath + '.tasks.list');
  }

  public updateAttachment() {
    this.updateFormValidity();
    this.saveTask('UPDATE_TASK_ATTACHMENTS');
  }

  public updateFormValidity() {
    Object.values(this.form.controls).forEach(c => {
      c.updateValueAndValidity();
    });
  }

  public functionNotificationAfterStatusUpdate(): void {
    this.isTaskStatusChanged = true;
    this.notifyFunction.sendByMail = false;
    this.notifyFunction.sendBySms = false;
    this.task['isFunctionNotified'] = true;
    this.saveTask('UPDATE_TASK_STATUS', this.form.get('status').value, false);
  }

  public updateStatus(): void {
    Object.values(this.form.controls).forEach(c => {
      c.markAsDirty();
      c.updateValueAndValidity();
    });
    //
    if (this.isOutputDataLabelDefined && this.erpTask.attachments.isEmpty()) {
      this.form.controls['attachments'].setErrors({ required: { valid: false } });
    }

    if (this.form.valid && !(this.isOutputDataLabelDefined && this.erpTask.attachments.isEmpty())) {
      this.saveTask('UPDATE_TASK_STATUS', this.form.get('status').value, true);
    } else {
      this.scrollToError();
      setTimeout(() => {
        this.statusControl.setValue(null);
        Object.values(this.form.controls).forEach(c => {
          c.markAsDirty();
          c.updateValueAndValidity();
        });
      }, 50);

      Object.values(this.form.controls).forEach(c => {
        c.markAsDirty();
        c.updateValueAndValidity();
      });
    }
    //****Trigger function notification after task status update */
    //  this.functionNotificationAfterStatusUpdate();
  }

  public openModalStatutNoConfirm() {
    this.modalsService.openPromptModal().then(res => {
      if (res) {
        if (res !== false) {
          this.task.status = 'FROZEN';
          this.commentControl.setValue(res);
          this.updateStatus();
        } else {
          this.statusControl.setValue(null);
          Object.values(this.form.controls).forEach(c => {
            c.markAsDirty();
            c.updateValueAndValidity();
          });
        }
      } else {
        this.statusControl.setValue(null);
        Object.values(this.form.controls).forEach(c => {
          c.markAsDirty();
          c.updateValueAndValidity();
        });
      }
    });
  }

  public openFormModal() {
    this.modalsService
      .openFormModal({
        formIoCredentials: this.formIoCredentials,
        isHolder: this.isHolder,
        task: this.task,
        translateKey: this.moduleConfig.config.translateKey,
      })
      .then(res => {
        if (res) {
          console.debug(res);
        }
      });
  }

  public saveTask(historyLogi18nKey: string, status?: string, goBack = false): void {
    if (!this.isHolder && status && ['DONE', 'FROZEN', 'NOT_APPLICABLE'].includes(status)) {
      this.modalsService
        .openAlertModal({
          title: this.translate.transform('ERP.MODALS.ALERT_SAVE_NOTIFY_HOLDER.TITLE', { fct: this.functionTitle }),
          content: this.translate.transform('ERP.MODALS.ALERT_SAVE_NOTIFY_HOLDER.CONTENT', {
            fct: this.functionTitle,
            holder: this.userHolder ? this.userHolder.firstName + ' ' + this.userHolder.lastName : '',
          }),
        })
        .then(() => {
          this.proceedToTaskSaving(historyLogi18nKey, status, goBack);
        });
    } else {
      this.proceedToTaskSaving(historyLogi18nKey, status, goBack);
    }
  }

  public preSaveTask(): void {
    //Etiquette empty field control
    if (this.context.module === 'erp' && !this.erpTask.customOutputDataLabel.trim().length) {
      this.modalsService.openAlertModal({
        title: 'Alerte Etiquette vide!',
        content: '<br><p>veuillez renseigner une étiquette!<p>',
      });
    } else {
      this.saveTask('UPDATE_TASK_LABEL');
    }
  }

  public toggleLabelEdit(isLabelEditable: boolean): void {
    if (isLabelEditable) {
      this.dialog.open(this.editLabel, {
        width: '630px',
      });
    }
  }

  public saveLabelEdit(): void {
    //this.erpTask.outputDataLabel = this.formLabelInput.get('title').value;
    this.dialog.closeAll();
    this.saveTask('UPDATE_TASK_LABEL');
  }

  public cancelLabelEdit(): void {
    this.dialog.closeAll();
  }

  public scrollToError() {
    setTimeout(() => {
      const invalidElems = document.getElementsByClassName('mat-error');
      if (invalidElems.length) {
        invalidElems[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    });
  }

  public toggleView(option: string) {
    this[option] = !this[option];
  }

  hasAttachments(task: HolTask) {
    return task.attachments && !task.attachments.isEmpty();
  }

  isAttachmentsMandatory(task: HolTask) {
    return this.tasksService.isAttachmentsMandatory(task);
  }

  canChangeAcl(task: HolTask) {
    return this.tasksService.canChangeAcl(task);
  }

  saveNotifications(notifications: HolNotification[]) {
    this.notifications = cloneDeep(notifications);
  }

  saveNotifyFunction(notifyFunction: { sendByMail: boolean; sendBySms: boolean }) {
    this.notifyFunction = cloneDeep(notifyFunction);
  }

  ngOnDestroy(): void {
    this.logSubscription.unsubscribe();
    this.taskSubscription.unsubscribe();
    this.rolesNotifsSubscription.unsubscribe();
  }

  changeVisibility(event: ErpVisiblityStatus): void {
    this.erpTask.customVisibleBy = event;
  }

  changeFrozenByErd(event: boolean): void {
    this.erpTask.frozenByErd = event;
  }

  private initForm() {
    const disabled = this.task.readOnly || !!this.task.status;
    this.form = new FormGroup({});
    this.attachmentControl = new FormControl(this.task.attachments, this.attachmentRequiredValidator.bind(this));
    this.statusControl = new FormControl(
      {
        value: this.task.status,
        disabled,
      },
      Validators.required,
    );
    this.commentControl = new FormControl(
      {
        value: this.task.comment || '',
        disabled,
      },
      this.commentRequiredValidator.bind(this),
    );
    this.form.addControl('attachments', this.attachmentControl);
    this.form.addControl('status', this.statusControl);
    this.form.addControl('comment', this.commentControl);

    // hide and show toggle buttons to force recompute due to bug when changing disabled on toggle button group
    // (https://github.com/angular/components/issues/11608)
    this.hackShowToggleButton = false;
    setTimeout(() => {
      this.hackShowToggleButton = true;
    });
  }

  private attachmentRequiredValidator(c: FormControl) {
    return this.statusControl && this.statusControl.value === 'DONE' && this.isAttachmentsMandatory(this.task) && c.value.isEmpty()
      ? { required: { valid: false } }
      : null;
  }

  private commentRequiredValidator(c: FormControl) {
    return this.statusControl && this.statusControl.value === 'FROZEN' && (!c.value || !c.value.trim().length)
      ? { required: { valid: false } }
      : null;
  }

  private proceedToTaskSaving(historyLogi18nKey: string, status?: string, goBack = false): void {
    this.isSaving = true;

    let changeStatusNotification = {
      canSendNotification: false,
      comment: undefined,
      functionTitle: undefined,
      type: undefined,
    };

    if (status && status.trim().length > 0) {
      this.task.status = status;
      changeStatusNotification.canSendNotification = true;
      changeStatusNotification.comment = this.commentControl.value.trim().length ? this.commentControl.value.trim() : undefined;
      changeStatusNotification.functionTitle = this.functionTitle;
      changeStatusNotification.type = status;
      this.task.comment = status === 'TODO' ? null : this.commentControl.value;
    } else {
      this.task.comment = '';
    }
    this.task.tags = this.tags;
    if (this.erpTask) {
      if (this.erpTask.attachments.isEmpty()) {
        this.erpTask.customIsOnDashboard = false;
        this.erpTask.customOutputDataLabel = null;
      } else {
        this.erpTask.customIsOnDashboard = true;
        if (!this.erpTask.outputDataLabel) {
          this.erpTask.customOutputDataLabel = this.erpTask.outputTitle;
        }
      }
    }
    this.tasksService
      .save(
        this.task,
        {
          notifications: changeStatusNotification.canSendNotification ? this.notifications : [],
          functionToNotify:
            (this.notifyFunction.sendByMail || this.notifyFunction.sendBySms) && changeStatusNotification.canSendNotification
              ? this.notifyFunction
              : undefined,
        },
        historyLogi18nKey,
        changeStatusNotification,
      )
      .then(() => {
        if (this.task.status === 'TODO') {
          delete this.task.status;
        }
        changeStatusNotification = {
          canSendNotification: false,
          comment: undefined,
          functionTitle: undefined,
          type: undefined,
        };
        this.isSaving = false;
        if (goBack) {
          this.goBack();
        } else {
          this.initForm();
        }
      });
  }

  public isNoteSave(event: boolean) {
    //console.log('isNoteSave', event);
  }
}
