import { FormGroup } from '@angular/forms';
import { HolObject } from '../../../models/hol-object';
import { HolNotification, HolNotifyFunction } from '../../../models/hol-notification.model';
import { nextInfoType } from '../../../components/hol-next-info/hol-next-info.component';
import { ComponentType } from '@angular/cdk/portal';
import { Internal } from '../../../../ocl/models/ocl-interface.model';

export class HolModalConfigBuilder {
  private config = new HolModalConfig();

  withItem(item: HolObject): HolModalConfigBuilder {
    this.config.item = item;
    return this;
  }

  setReadOnly(isReadOnly: boolean): HolModalConfigBuilder {
    this.config.isReadOnly = isReadOnly;
    return this;
  }

  setCreate(isCreate: boolean): HolModalConfigBuilder {
    this.config.isCreate = isCreate;
    return this;
  }

  setType(type: string): HolModalConfigBuilder {
    this.config.type = type;
    return this;
  }

  setContext(context: HolModalContext): HolModalConfigBuilder {
    this.config.context = context;
    return this;
  }

  setCanUpdate(canUpdate: boolean): HolModalConfigBuilder {
    this.config.canUpdate = canUpdate;
    return this;
  }

  setContentTextLenght(contentLenght: number): HolModalConfigBuilder {
    this.config.contentTextLength = contentLenght;
    return this;
  }

  setLimitTo(limitToObject: HolObject): HolModalConfigBuilder {
    this.config.limitTo = limitToObject;
    return this;
  }

  setCanArchive(canArchive: boolean): HolModalConfigBuilder {
    this.config.canArchive = canArchive;
    return this;
  }

  setNotifications(notif: HolNotification[]): HolModalConfigBuilder {
    this.config.notifications = notif;
    return this;
  }

  setHistoryItemComponent(component: ComponentType<any>): HolModalConfigBuilder {
    this.config.historyItemComponent = component;
    return this;
  }

  setHistoryItemObjectId(objectId: string): HolModalConfigBuilder {
    this.config.historyItemObjectId = objectId;
    return this;
  }

  setHistoryItemType(type: string): HolModalConfigBuilder {
    this.config.historyItemType = type;
    return this;
  }

  setHistoryTitle(title: string): HolModalConfigBuilder {
    this.config.historyTitle = title;
    return this;
  }

  setIsHistory(isHistory: boolean): HolModalConfigBuilder {
    this.config.isHistory = isHistory;
    return this;
  }

  setCanEditTitle(canEdit: boolean): HolModalConfigBuilder {
    this.config.canEditTitle = canEdit;
    return this;
  }

  setForm(form?: FormGroup): HolModalConfigBuilder {
    this.config.form = form;
    return this;
  }

  setIsOnSummary(isOnSummary: boolean): HolModalConfigBuilder {
    this.config.isOnSummary = isOnSummary;
    return this;
  }

  build(): HolModalConfig {
    return this.config;
  }
}

export class HolModalConfig implements Internal {
  item: HolObject;
  isReadOnly?: boolean = false;
  isCreate?: boolean = true;
  type?: string;
  form?: FormGroup;
  contentTextLength?: number;
  limitTo?: HolObject;
  context?: HolModalContext;
  canArchive: boolean = false;
  canSave: boolean = true;
  nextInfo: nextInfoType = undefined;
  hideSecondaryColumn: boolean = false; //utile ?
  hasAttachments: boolean = true;
  hasContentText: boolean = true;
  hasAttachmentsMandatory: boolean = false;
  addButtonTrad: string = 'DASHBOARD.MODAL.ADD_BUTTON';
  updateButtonTrad: string = 'DASHBOARD.MODAL.UPDATE_BUTTON';
  canUpdate: boolean = true;
  notifications: HolNotification[] = [];
  historyItemComponent: ComponentType<any>;
  historyItemObjectId: string;
  historyItemType: string;
  historyTitle: string;
  historyItemListMode: boolean = false;
  canEditTitle: boolean = false;
  isHistory: boolean = false;
  isUTC: boolean = true;
  extendACLOnly: boolean = false;
  disabledValidateActions: boolean = false;
  notifyFunction: HolNotifyFunction = undefined;
  applicability = undefined;
  canUpdateFromOtherModule: boolean = false;
  fastMode: boolean = false;
  isOnSummary: boolean = false;
  transfertToERP: boolean = false;
  transfertToDecision: boolean = false;
  transfertToLogbook: boolean = false;
  haveGroup: boolean = false;
  haveDecisionLink: boolean = false;
  haveFlightLink: boolean = false;
  haveEventLink: boolean = false;
  haveLogbookLink: boolean = false;
  isPrivate: boolean = false;
  forceOpenAttachments: boolean = false;
}

export class HolModalContext {
  module: string;
  category: string;
  htmlTitle: string;
  htmlDate: string;
  htmlTemplate: string;
}

export class HolModalOutput {
  contentTextChanged?: boolean;
  notifications?: HolNotification[];
  nextInfo?: nextInfoType;
  notifyFunction?: HolNotifyFunction;
}

export const modalButtonConfig: ButtonConfig[] = [
  {
    name: 'NOTIFICATION',
    icon: 'campaign',
    access: {
      LOGBOOK: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      Event: ['GOC', 'OCC', 'OPS', 'MCC'],
      FlightInstruction: ['GOC', 'OPS'],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: ['CREW'],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: ['ERP', 'ECL'],
    },
    type: 'ACTION',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'ATTACHMENTS',
    icon: 'attachment',
    access: {
      LOGBOOK: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      Event: ['GOC', 'MCC', 'OCC', 'OPS'],
      FlightInstruction: ['GOC', 'OPS'],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: ['CREW', 'ECL', 'GOC', 'MCC', 'OCC', 'OPS'],
      Task: ['ERP', 'OCC', 'GOC', 'OPS'],
      FLIGHT: [],
      ASSET: ['ECL'],
      DOCUMENT: ['ERP', 'ECL'],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TAG',
    icon: 'shoppingmode',
    access: {
      LOGBOOK: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'ERP', 'GOC', 'OCC', 'OPS'],
      Event: ['GOC', 'OCC', 'OPS'],
      FlightInstruction: ['GOC', 'OPS'],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: ['CREW', 'GOC', 'MCC', 'OCC', 'OPS'],
      Task: ['ERP', 'OCC', 'GOC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: ['ERP', 'ECL'],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'APPLICABILITY',
    icon: 'flightsmode',
    access: {
      LOGBOOK: ['GOC', 'OPS'],
      DECISION: ['GOC', 'OPS'],
      Event: ['GOC', 'OPS'],
      FlightInstruction: ['GOC', 'OPS'],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TASK CREATE',
    icon: 'add_task',
    access: {
      LOGBOOK: [],
      // DECISION: ['ECL', 'ERP'],
      DECISION: ['ERP'],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'ACTION',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'CHECK_BOX',
    icon: 'check',
    access: {
      LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TIMER_ALERT',
    icon: 'alarm',
    access: {
      // LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      LOGBOOK: [],
      DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: ['GOC', 'OPS'],
      GLOBALINSTRUCTION: [],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: ['ERP'],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'DECISION_LINK',
    icon: 'link',
    access: {
      //LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      LOGBOOK: ['GOC', 'OCC', 'OPS'],
      DECISION: [],
      // Event: ['GOC', 'OCC', 'OPS'],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: ['GOC', 'OPS'],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'FLIGHT_ATTACHMENTS',
    icon: 'airplane_ticket',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: ['GOC', 'OPS'],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'GROUP_LINK',
    icon: 'link',
    access: {
      LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: ['GOC', 'OCC', 'OPS'],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: ['CREW', 'ECL', 'GOC', 'MCC', 'OCC', 'OPS'],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'FLIGHT_LINK',
    icon: 'link',
    access: {
      LOGBOOK: ['OCC'],
      DECISION: ['OCC'],
      Event: [],
      // Event: ['OCC'],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'EVENT_LINK',
    icon: 'link',
    access: {
      LOGBOOK: ['OCC', 'GOC', 'OPS'],
      DECISION: ['OCC', 'GOC', 'OPS'],
      Event: [],
      FlightInstruction: [],
      FlightLogbook: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'INTERNAL',
    icon: 'vpn_key',
    access: {
      LOGBOOK: ['GOC'],
      DECISION: ['GOC'],
      Event: ['GOC'],
      FlightLogbook: ['GOC'],
      FlightInstruction: ['GOC'],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'LOGBOOK_LINK',
    icon: 'link',
    access: {
      LOGBOOK: [],
      DECISION: ['GOC', 'OCC', 'OPS'],
      //DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: [],
      //  Event: ['GOC', 'OCC', 'OPS'],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'MAKE_TODO',
    icon: 'checklist',
    access: {
      LOGBOOK: [],
      DECISION: ['GOC', 'OCC', 'OPS'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'NEXT_INFO',
    icon: 'keyboard_tab',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      //Event: ['GOC', 'MCC', 'OCC', 'OPS'],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TASK_COMMENT',
    icon: 'speaker_notes',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'PARAMETER',
    stateType: ['UPDATE'],
  },
  {
    name: 'TASK_RESET',
    icon: 'restart_alt',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'ACTION',
    stateType: ['UPDATE'],
    customClass: 'action action-refresh',
  },
  {
    name: 'TASK_NOT_APPLICABLE',
    icon: ' speaker_notes_off',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'ACTION',
    stateType: ['UPDATE'],
  },
  {
    name: 'TASK_REJECTED',
    icon: 'do_not_disturb_on',
    access: {
      LOGBOOK: [],
      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: ['ERP', 'GOC', 'OCC', 'OPS'],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'ACTION',
    stateType: ['UPDATE'],
  },
  {
    name: 'PIN',
    icon: 'push_pin',
    access: {
      LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TO_DECISION',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: ['ECL', 'GOC', 'OCC', 'OPS'],
      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TRANSFER',
    stateType: ['UPDATE'],
  },
  {
    name: 'TO_ECL',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: [],

      DECISION: [],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TRANSFER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TO_ERP',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: ['ECL'],
      DECISION: ['ECL'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TO_FLIGHT_LOGBOOK_INSTRUCTION',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: [],
      DECISION: ['GOC', 'OPS'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TRANSFER',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TO_GOC',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: ['OCC'],
      DECISION: ['OCC'],
      Event: ['OCC'],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  {
    name: 'TO_LOGBOOK',
    icon: 'switch_access_2',
    access: {
      LOGBOOK: [],
      DECISION: ['ECL', 'GOC', 'OCC', 'OPS'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TRANSFER',
    stateType: ['UPDATE'],
  },
  {
    name: 'TO_SUMMARY',
    icon: 'short_text',
    access: {
      LOGBOOK: ['ECL'],
      DECISION: ['ECL'],
      Event: [],
      FlightLogbook: [],
      FlightInstruction: [],
      GLOBALINSTRUCTION: [],
      Task: [],
      FLIGHT: [],
      ASSET: [],
      DOCUMENT: [],
    },
    type: 'TOGGLE',
    stateType: ['CREATE', 'UPDATE'],
  },
  //  modalType: ["LOGBOOK","DECISION","Event","FlightLogbook"]},
];

export type ModalType =
  | 'LOGBOOK'
  | 'DECISION'
  | 'Event'
  | 'FlightLogbook'
  | 'FlightInstruction'
  | 'GLOBALINSTRUCTION'
  | 'Task'
  | 'FLIGHT'
  | 'ASSET'
  | 'DOCUMENT';

export type ModuleType = 'ECL' | 'ERP' | 'GOC' | 'OCC' | 'OPS' | 'CREW' | 'MCC';
export type ButtonType = 'ACTION' | 'PARAMETER' | 'TOGGLE' | 'TRANSFER';
export type StateType = 'CREATE' | 'UPDATE';

export interface ButtonConfig {
  name: string;
  access: Record<ModalType, ModuleType[]>;
  icon: string;
  type: ButtonType;
  stateType: StateType[];
  customClass?: string;
}

export interface ButtonToggle extends ButtonConfig {
  value: boolean;
}

export interface ButtonParameter extends ButtonConfig {
  isContentDisplay: boolean;
}
