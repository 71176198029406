import { MsalConfiguration } from './hol-msal.model';
import { environment } from '../../environments/environment';

export const getMsalConfig = async <T>(): Promise<MsalConfiguration> => {
  let config: MsalConfiguration;
  try {
    //console.log('environment', environment);
    // console.log('Fetching msal config...', `${environment.parseServerUrl.replace(/\/[a-zA-Z0-9]\/+$/, '')}/msalConfig`);
    const response = await fetch(`${environment.parseServerUrl.replace(/\/[a-zA-Z0-9]\/+$/, '')}/msalConfig`);
    config = await response.json();
  } catch (e) {
    console.warn('No msal config!');
    config = {
      isMsalActivated: false,
      msalClientId: '',
      msalAuthority: '',
    };
  }
  return config;
};
