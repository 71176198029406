import { take } from 'rxjs/operators';
import { OclScenariosStoreManager } from '../../store/scenarios/ocl-scenarios.store-manager';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { cloneDeep, differenceWith, extend, isEqual, orderBy } from 'lodash';
import { EclInfos } from 'src/app/common/store/common.model';

import { HolAttachments } from '../../../common/models/hol-attachments.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { HolScenario } from '../../../common/models/hol-scenario';
import { HolTag } from '../../../common/models/hol-tag';
import { DialogService } from '../../../common/services/dialog/dialog.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { GocEventService } from '../../../goc/services/goc-event-service/goc-event.service';
import { MccEventService } from '../../../mcc/services/mcc-event.service';
import { OclEvent } from '../../models/ocl-event.model';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclScenarioService } from '../../services/ocl-scenario-service/ocl-scenario.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclEventCloseModalComponent } from '../ocl-event-close-modal/ocl-event-close-modal.component';
import { AlertModalComponent } from 'src/app/common/modals/alert-modal/alert-modal.component';
import { HolObject } from '../../../common/models/hol-object';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { EventInfos } from '../../../common/models/hol-event';
import { utc } from 'moment';
import { HolChecklist } from '../../../common/models/hol-checklist';
import {
  ButtonConfig,
  ButtonParameter,
  ButtonToggle,
  ButtonType,
  HolModalConfig,
  modalButtonConfig,
  ModuleType,
} from '../../../common/modals/hol-modal/config/HolModalConfig';
import { SectionConfig } from '../../../common/modals/hol-modal/hol-modal.component';
import {
  Done,
  InSummary,
  isDone,
  isInSummary,
  isIsPinned,
  IsPinned,
  isToErp,
  ToErp,
  Internal,
  isInternal,
} from '../../models/ocl-interface.model';
import { groupBy } from '../../../common/utils/map-utils';
import { HOLOptions, HolOptionsService } from '../../../common/services/hol-options.service';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';

@Component({
  selector: 'app-ocl-event-modal',
  templateUrl: './ocl-event-modal.component.html',
  styleUrls: ['./ocl-event-modal.component.scss'],
})
export class OclEventModalComponent implements OnInit, OnDestroy, AfterContentChecked {
  loading: boolean;
  isCreate: boolean;
  isUtc: boolean;
  isReadOnly: boolean;
  form: FormGroup = new FormGroup({});
  scenarios: HolScenario[] = [];
  selectedTags: HolTag[] = [];
  attachments: HolAttachments = new HolAttachments();
  notifications: HolNotification[] = [];
  fromLogbook: any;
  fromDecision: any;
  event: OclEvent;
  nextInfos: EventInfos[] = [];
  isNextInfoMandatory: boolean;
  fromOtherUnivers = false;
  eclInfos: EclInfos;
  hasToActivateECL = false;
  hasToDeactivateECL = false;
  hasEclAccess;
  eclName = '';
  initialEvent: OclEvent = new OclEvent();
  initialACL: Parse.ACL;
  public eventGroup: OclEventGroup[] = [];
  public linkedGroup: string[] = [];
  private infoHasBeenAdded = false;
  placeHolderDescription: string;
  private commonStoreManagerSub: Subscription;
  context;
  public airportsCode: Array<string>;
  public applicabilityIsReadOnly: boolean;
  duplicateToOtherModule = false;
  oldDuplicateToOtherModuleValue = null;
  newDuplicateToOtherModuleValue = null;
  copyToGOC: boolean;
  copyFromGOC: boolean;
  public objectKeys = Object.keys;
  public scenariosByGroup = {};
  public checkListTasks: HolChecklist[] = [];
  protected readonly isFinite = isFinite;
  protected readonly utc = utc;
  public isNoteSaved: boolean = true;

  public get showCheckboxes(): boolean {
    return true;
  }

  public groupButton: Map<ButtonType, ButtonConfig[]> = new Map();
  public keyButton: Map<string, ButtonConfig> = new Map();
  public sections: SectionConfig[] = [];
  public actionMode: boolean = false;
  protected templateMap: Map<string, TemplateRef<Component>> = new Map();

  public config: HolModalConfig;
  public config$: BehaviorSubject<HolModalConfig>;

  @ViewChild('template_TAG', { static: true, read: TemplateRef }) templateTAG: TemplateRef<any>;
  @ViewChild('template_ATTACHMENTS', { static: true, read: TemplateRef }) templateATTAMENTS: TemplateRef<any>;
  @ViewChild('template_GROUP_LINK', { static: true, read: TemplateRef }) templateGROUP_LINK: TemplateRef<any>;
  @ViewChild('template_GROUP_DECISION', { static: true, read: TemplateRef }) templateDECISION_LINK: TemplateRef<any>;
  @ViewChild('template_FLIGHT_LINK', { static: true, read: TemplateRef }) templateFLIGHT_LINK: TemplateRef<any>;
  @ViewChild('template_EVENT_LINK', { static: true, read: TemplateRef }) templateEVENT_LINK: TemplateRef<any>;
  @ViewChild('template_LOGBOOK_LINK', { static: true, read: TemplateRef }) templateLOGBOOK_LINK: TemplateRef<any>;
  @ViewChild('template_APPLICABILITY', { static: true, read: TemplateRef }) templateAPPLICABILITY: TemplateRef<any>;
  @ViewChild('template_TIMER_ALERT', { static: true, read: TemplateRef }) templateTIMER_ALERT: TemplateRef<any>;
  @ViewChild('NOT_IMPLEMENTED', { static: true, read: TemplateRef }) workInProgress: TemplateRef<any>;
  @ViewChild('scrollTargetModalHol', { static: false }) scrollTarget: ElementRef;

  private buttonAvailable: ButtonConfig[] = [];
  private holOptions: HOLOptions;
  public contentTextLength = 160;

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogService: DialogService,
    protected dialogRef: MatDialogRef<OclEventModalComponent>,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected scenarioService: OclScenarioService,
    protected translate: TranslatePipe,
    protected optionsService: OclOptionsService,
    protected mccEventsService: MccEventService,
    protected gocEventService: GocEventService,
    protected flightsStatusService: OclFlightStatusService,
    protected eventService: OclEventService,
    protected dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    protected commonStoreManager: CommonStoreManager,
    protected scenarioStoreManager: OclScenariosStoreManager,
    protected groupStoreManager: OclGroupsStoreManager,
    protected groupService: OclGroupService,
    protected cdr: ChangeDetectorRef,
    public holOptionsService: HolOptionsService,
  ) {
    this.airportsCode = this.data.airportsCode;
    this.hasEclAccess = $rootScope.accessRights.ecl !== 'UNAUTHORIZED';
    this.notifications = data.notifications;
    this.isUtc = data.isUtc;
    this.fromLogbook = data.fromLogbook;
    this.fromDecision = data.fromDecision;
    this.isCreate = !data.event;

    this.fromOtherUnivers =
      this.data.event && (this.data.event.fromMCC === true || this.data.event.fromGOC === true || this.data.event.fromOCC === true);

    this.isReadOnly = data.isReadOnly;
    this.form.addControl('scenario', new FormControl());
    this.form.addControl('description', new FormControl());
    this.form.addControl('hasNI', new FormControl());
    this.form.addControl('hasToActivateCheckLists', new FormControl());
    this.placeHolderDescription = this.isCreate
      ? this.translate.transform(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DESCRIPTION_LABEL')
      : this.translate.transform(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DESCRIPTION_LABEL_EDIT_MODE');
  }

  ngAfterContentChecked() {
    if (this.isCreate) {
      if (this.moduleConfig.config.forceEventChecklistActivation) {
        this.form.get('hasToActivateCheckLists').setValue(true);
      }
      if (this.optionsService.getDefaultScenario()) {
        const defaultScenario = this.scenarios.find(s => s.code === this.optionsService.getDefaultScenario());
        if (defaultScenario) {
          this.form.get('scenario').setValue(defaultScenario);
          //   this.form.get('scenario').setValue(defaultScenario);
        }
      }
      if (this.fromDecision) {
        this.form.get('description').setValue(this.fromDecision.message);
      }
      if (this.fromLogbook) {
        this.form.get('description').setValue(this.fromLogbook.text);
      }
    }
    this.cdr.detectChanges();
  }

  private initTemplate() {
    this.templateMap.set('workInProgress', this.workInProgress);
    this.templateMap.set('TAG', this.templateTAG);
    this.templateMap.set('ATTACHMENTS', this.templateATTAMENTS);
    this.templateMap.set('GROUP_LINK', this.templateGROUP_LINK);
    this.templateMap.set('DECISION_LINK', this.templateDECISION_LINK);
    this.templateMap.set('FLIGHT_LINK', this.templateFLIGHT_LINK);
    this.templateMap.set('EVENT_LINK', this.templateEVENT_LINK);
    this.templateMap.set('APPLICABILITY', this.templateAPPLICABILITY);
    this.templateMap.set('LOGBOOK_LINK', this.templateLOGBOOK_LINK);
    this.templateMap.set('TIMER_ALERT', this.templateTIMER_ALERT);
  }

  async ngOnInit() {
    this.loading = true;
    this.initTemplate();

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'EVENT',
      htmlTitle: this.form.value.description ? this.form.value.description : '',
      htmlDate: this.event && this.event.createdAt ? this.event.createdAt : undefined,
      htmlTemplate: 'A',
    };

    // Groups
    this.groupStoreManager.groupsState.subscribe(g => {
      this.eventGroup = g.events;
    });
    if (!this.config) {
      this.config = new HolModalConfig();
    }

    if (!this.isCreate) {
      this.initialEvent = cloneDeep(this.data.event);
      if (this.initialEvent.fromGOC) {
        this.form.addControl(
          'fromGOC',
          new FormControl({
            value: this.initialEvent.fromGOC ? true : false,
          }),
        );
      }
      if (this.moduleConfig.config.activateEventNotification) {
        this.form.addControl(
          'hasToActivateNotification',
          new FormControl({
            value: this.moduleConfig.config.forceEventNotification,
            disabled: this.moduleConfig.config.forceEventNotification,
          }),
        );
      }
    }
    this.copyToGOC = cloneDeep(this.initialEvent.toGOC);
    this.copyFromGOC = cloneDeep(this.initialEvent.fromGOC);
    this.form.addControl(
      'toGOC',
      new FormControl({
        value: this.initialEvent.toGOC ? this.initialEvent.toGOC : false,
        disabled: this.isReadOnly || this.initialEvent.closeReason,
      }),
    );
    this.commonStoreManagerSub = this.commonStoreManager.commonState.subscribe(commonState => {
      this.eclInfos = commonState.eclInfos;
    });
    this.isNextInfoMandatory =
      this.optionsService.getIsNextInfoMandatory() !== undefined ? this.optionsService.getIsNextInfoMandatory() : true;

    await this.initScenarios(this.data);
    if (!this.isCreate) {
      // this.form.get('scenario').setValue(this.scenarios.find(s => s.code === this.initialEvent.scenario.code));
      // this.form.get('description').setValue(this.initialEvent.description);
      this.selectedTags = cloneDeep(this.initialEvent.tags);
      this.attachments = cloneDeep(this.initialEvent.attachments);
      this.nextInfos = this.initialEvent.infos.map(i => {
        return { info: i, notifications: null };
      });
      //   this.form.get('hasToActivateCheckLists').setValue(this.initialEvent.checklistActivated);
      this.initialACL = cloneDeep(this.initialEvent.acl);

      this.config.haveGroup = this.linkedGroup.length > 0;
      this.config.item = this.event;
      this.config.limitTo = this.limitTo;
      this.config.canArchive = false;
      // this.config.canUpdateFromOtherModule = this.canUpdateFromOtherModule;
      this.config.contentTextLength = 200; // <= TODO mettre la valeur HolOptions
      this.config.context = this.context;
      this.config.type = 'EVENT';
      this.config.notifications = this.notifications;
      this.config.isReadOnly = this.isReadOnly;
      this.config.isCreate = false;

      if (this.attachments) {
        try {
          this.event.attachments = this.attachments;
          this.config.item = this.event;
        } catch (e) {}
      }

      //TODO VOIR

      this.config.historyItemComponent = this.data.itemComponent;
      this.config.historyItemListMode = true;
      //this.config.historyItemObjectId = this.event.objectId;
      this.config.historyItemType = 'event';
      this.config.historyTitle = this.moduleConfig.config.translateKey + '.MODALS.HISTORY.EVENT';
    } else {
      if (this.moduleConfig.config.forceEventChecklistActivation) {
        //   this.form.get('hasToActivateCheckLists').setValue(true);
      }
      if (this.optionsService.getDefaultScenario()) {
        const defaultScenario = this.scenarios.find(s => s.code === this.optionsService.getDefaultScenario());
        if (defaultScenario) {
          this.form.get('scenario').setValue(defaultScenario);
        }
      }
      if (this.fromDecision) {
        //   this.form.get('description').setValue(this.fromDecision.message);
        this.attachments = this.fromDecision.attachments;
        this.selectedTags = this.fromDecision.tags;
        if (this.fromDecision.acl) {
          this.initialEvent.acl = this.fromDecision.acl;
        }
      }
      if (this.fromLogbook) {
        // this.form.get('description').setValue(this.fromLogbook.text);
        this.attachments = this.fromLogbook.attachments;
        this.selectedTags = this.fromLogbook.tags;
        if (this.fromLogbook.acl) {
          this.initialEvent.acl = this.fromLogbook.acl;
        }
      }

      this.config.hasAttachments = false;
      this.config.isCreate = true;
      this.config.haveGroup = false;
      this.config.item = this.event;
      this.config.limitTo = this.limitTo;
      this.config.canArchive = false;
      this.config.canEditTitle = true;
      // this.config.canUpdateFromOtherModule = this.canUpdateFromOtherModule;
      this.config.contentTextLength = 200; // <= TODO mettre la valeur HolOptions
      this.config.type = 'EVENT';
      this.config.notifications = this.notifications;
      this.config.isReadOnly = this.isReadOnly;
      this.config.isHistory = false;
      this.config.context = this.context;
    }

    if (this.config.notifications) {
      this.config.notifications = this.config.notifications.sort();
    }
    this.buttonAvailable = modalButtonConfig
      .filter(butConfig => {
        return (
          butConfig.access['Event'].includes(this.context.module as ModuleType) &&
          butConfig.stateType.includes(this.isCreate ? 'CREATE' : 'UPDATE')
        );
      })
      .map(but => {
        if (but.type == 'TOGGLE') {
          let toggle: ButtonToggle = {
            ...but,
            value: false,
          };
          return toggle;
        } else if (but.type == 'PARAMETER') {
          let param: ButtonParameter = {
            ...but,
            isContentDisplay: false,
          };
          return param;
        }
        return but;
      });

    //Stock les buttonConfig dans une map avec le Name en KEY
    this.keyButton = this.buttonAvailable.reduce((map, buttonConfig) => {
      map.set(buttonConfig.name, buttonConfig);
      return map;
    }, new Map<string, ButtonConfig>());
    //Creer un groupe pour l'affichage par type
    // Le composant TAG est supprimé car il a un comportement spécial
    this.groupButton = groupBy(
      this.buttonAvailable.filter(value => value.name !== 'TAG'),
      item => item.type,
    );

    await this.loadContent();

    if (this.data.event) {
      await this.getCheckListTasks();
    }

    // this.config$.next(this.config);

    this.loading = false;
    this.config$ = new BehaviorSubject<HolModalConfig>(this.config);

    if (!this.isCreate) {
      this.form.get('scenario').setValue(this.scenarios.find(s => s.code === this.initialEvent.scenario.code));
      this.form.get('description').setValue(this.initialEvent.description);
      this.form.get('hasToActivateCheckLists').setValue(this.initialEvent.checklistActivated);
    }
  }

  getNotificationActive() {
    return this.config.notifications.filter(notification => {
      return notification.sendByMail || notification.sendBySms;
    }).length;
  }

  protected async loadContent() {
    try {
      if (
        this.config.hasAttachments &&
        this.config.item.attachments &&
        this.config.item.attachments instanceof HolAttachments &&
        !this.config.item.attachments.isEmptyWithoutNote()
      ) {
        this.addItemOnSection(this.keyButton.get('ATTACHMENTS'));
      }
    } catch (e) {}

    if (!this.isCreate) {
      try {
        if (isIsPinned(this.config.item)) {
          let item = this.config.item as IsPinned;
          let but = this.keyButton.get('PIN');
          if (but) {
            (but as ButtonToggle).value = item.isPinned;
          }
        }
      } catch (e) {}

      try {
        if (isDone(this.config.item)) {
          let item = this.config.item as Done;
          let but = this.keyButton.get('CHECK_BOX');
          if (but) {
            (but as ButtonToggle).value = item.done;
          }
        }
      } catch (e) {}

      try {
        if (isInSummary(this.config.item)) {
          // @ts-ignore
          let item = this.item as InSummary;
          let but = this.keyButton.get('TO_SUMMARY');
          if (but) {
            (but as ButtonToggle).value = item.summary != null;
          }
        }
      } catch (e) {}

      try {
        if (isToErp(this.config.item)) {
          let item = this.config.item as ToErp;
          let but = this.keyButton.get('TO_ERP');
          if (but) {
            (but as ButtonToggle).value = item.toERP;
          }
        }
      } catch (e) {}
      try {
        if (this.copyToGOC) {
          let but = this.keyButton.get('TO_GOC');
          if (but) {
            (but as ButtonToggle).value = this.copyToGOC;
          }
        }
      } catch (e) {}
    }
    try {
      if (isInternal(this.config)) {
        let item = this.config as Internal;
        let but = this.keyButton.get('INTERNAL');
        if (but) {
          (but as ButtonToggle).value = this.config.isPrivate;
        }
      }
    } catch (e) {}
    // setTimeout(() => {
    //  console.log('timeout this.config.haveGroup', this.config.haveGroup);
    if (this.config.haveGroup) {
      this.addItemOnSection(this.keyButton.get('GROUP_LINK'));
    }
    if (this.config.haveDecisionLink) {
      this.addItemOnSection(this.keyButton.get('DECISION_LINK'));
    }
    if (this.config.haveFlightLink) {
      this.addItemOnSection(this.keyButton.get('FLIGHT_LINK'));
    }
    if (this.config.haveEventLink) {
      this.addItemOnSection(this.keyButton.get('EVENT_LINK'));
    }
    if (this.config.haveLogbookLink) {
      this.addItemOnSection(this.keyButton.get('LOGBOOK_LINK'));
    }
    if (this.config.nextInfo && this.config.nextInfo.nextInfoTime) {
      this.addItemOnSection(this.keyButton.get('TIMER_ALERT'));
    }

    //cleanup
    if (this.config.notifications) {
      this.config.notifications = this.config.notifications.map(notif => {
        notif.sendBySms = false;
        notif.sendByMail = false;
        return notif;
      });
    }

    this.holOptions = await this.holOptionsService.get(false);

    this.contentTextLength = this.holOptions.fieldDataLimit;
  }

  public toggleMode() {
    this.actionMode = !this.actionMode;
  }

  private addItemOnSection(buttonConfig: ButtonConfig, withScroll: boolean = false) {
    this.sections.push({
      key: buttonConfig.name,
      button: buttonConfig,
      template: this.templateMap.get(buttonConfig.name) || this.templateMap.get('workInProgress'),
    });

    if (withScroll) {
      setTimeout(() => {
        this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }, 500);
    }
  }

  private async getCheckListTasks(): Promise<void> {
    this.checkListTasks = (await this.eventService.getCheckListTaskByEvent(this.data.event.objectId, this.context.module)).filter(
      checkListTask => checkListTask !== undefined,
    );
  }

  private async initScenarios(data: { scenarios: HolScenario[] }) {
    if (data.scenarios) {
      // If scenarios are defined in dialog data
      this.scenarios = data.scenarios;
    } else {
      // If scenarios are not defined in dialog data
      this.scenarios = await this.scenarioStoreManager.scenariosState.pipe(take(1)).toPromise();
    }

    this.scenarios = orderBy(this.scenarios, 'code');

    if (this.moduleConfig.config.scenariosByGroup) {
      this.scenariosByGroup = this.scenarioService.orderByGroupingCode(this.scenarios);
    }
  }

  onButtonClick(buttonConfig: ButtonConfig) {
    switch (buttonConfig.type) {
      case 'ACTION':
        // Implémentez la logique pour le type ACTION

        this.actionMode = !this.actionMode;
        break;
      case 'PARAMETER':
        // Implémentez la logique pour le type PARAMETER
        let paramButton: ButtonParameter;
        paramButton = buttonConfig as ButtonParameter;
        paramButton.isContentDisplay = !paramButton.isContentDisplay;
        const tempSection = this.sections.filter(section => section.key === buttonConfig.name);
        if (tempSection.length > 0 && tempSection[0]) {
          this.sections = this.sections.filter(section => section.key !== buttonConfig.name);
        } else {
          this.addItemOnSection(buttonConfig, true);
        }

        break;
      case 'TOGGLE':
        // Implémentez la logique pour le type TOGGLE

        let toggleButton: ButtonToggle;
        toggleButton = buttonConfig as ButtonToggle;
        toggleButton.value = !toggleButton.value;

        switch (toggleButton.name) {
          case 'PIN':
            (this.config.item as IsPinned).isPinned = toggleButton.value;
            //   (this.item as IsPinned).isPinned = toggleButton.value;

            break;
          case 'CHECK_BOX':
            (this.config.item as Done).done = toggleButton.value;
            //   (this.item as Done).done = toggleButton.value;
            break;
          case 'TO_SUMMARY':
            this.config.isOnSummary = toggleButton.value;
            //    this.changeItems.emit(this.config);

            break;
          case 'TO_ERP':
            this.config.transfertToERP = toggleButton.value;
            //   this.changeItems.emit(this.config);
            break;

          case 'TO_GOC':
            this.copyToGOC = toggleButton.value;
            //   this.changeItems.emit(this.config);
            break;

          case 'INTERNAL':
            this.config.isPrivate = toggleButton.value;
            this.form.get('isPrivate').setValue(toggleButton.value);
            break;
        }

        break;
      case 'TRANSFER':
        switch (buttonConfig.name) {
          case 'TO_DECISION':
            //TODO to decision
            this.config.transfertToDecision = true;
            // this.changeItems.emit(this.config);
            break;
          case 'TO_LOGBOOK':
            //TODO to decision
            this.config.transfertToLogbook = true;
            // this.changeItems.emit(this.config);
            break;
          case 'TO_ERP':
            //TODO to decision
            this.config.transfertToERP = true;
            //   this.changeItems.emit(this.config);
            break;
        }
        // Implémentez la logique pour le type TRANSFER
        break;
    }

    this.cdr.detectChanges();
  }

  isButtonToggled(buttonConfig: ButtonConfig | ButtonToggle): boolean {
    if (buttonConfig.type === 'TOGGLE') {
      return (buttonConfig as ButtonToggle).value;
    }
    return false;
  }

  isContentDisplay(buttonName: string): boolean {
    const section = this.sections.filter(section => section.key === buttonName);
    return section && section.length && section.length > 0;
  }

  validate() {
    if (this.initialEvent.gocEvent && !this.form.get('fromGOC').value) {
      this.deleteGocEvent();
    } else {
      this.oldDuplicateToOtherModuleValue = this.initialEvent.toGOC;
      this.newDuplicateToOtherModuleValue = this.form.get('toGOC').value;
      this.duplicateToOtherModule = this.form.get('toGOC').value;
      this.initialEvent.toGOC = this.form.get('toGOC').value;

      if (this.isCreate) {
        this.createEvent();
      } else {
        this.updateEvent();
      }
    }
  }

  updateEvent() {
    if (this.fromOtherUnivers && this.moduleConfig.config.moduleName === 'goc' && !this.initialEvent.fromOCC) {
      return this.dialogRef.close(this.initialEvent);
    }

    const event = cloneDeep(this.initialEvent);
    event.description = this.form.get('description').value;
    event.scenario = this.form.get('scenario').value;
    event.attachments = this.attachments;
    event.infos = this.nextInfos.map(ni => ni.info);
    event.tags = this.selectedTags;
    event.checklistActivated = this.form.get('hasToActivateCheckLists').value;

    const isChecklistActivatedInEditMode = !this.initialEvent.checklistActivated && this.form.get('hasToActivateCheckLists').value;
    const isNotificationUpdateActivated =
      this.moduleConfig.config.activateEventNotification && this.form.get('hasToActivateNotification').value;
    // Next info ajoutée et activate checklists checked
    if (this.initialEvent.checklistActivated && this.infoHasBeenAdded && isNotificationUpdateActivated) {
      const newInfos = differenceWith(event.infos, this.data.event.infos, (a: HolNextInfo, b: HolNextInfo) => a.objectId === b.objectId);
      this.eventService.sendNewEventInfoChecklistActivatedNotifications(event, newInfos);
    }
    if (this.hasToActivateECL) {
      this.eventService.activateECLModule(this.eclInfos.eclOptionsId, this.eclName, event, []);
    }
    if (this.hasToDeactivateECL) {
      this.eventService.deactivateECLModule(this.eclInfos.eclOptionsId);
    }

    if (
      !isEqual(event, this.initialEvent) ||
      !isEqual(event.acl, this.initialACL) ||
      this.isApplicabilityChange() ||
      this.isDuplicateChange()
    ) {
      this.loading = true;
      this.eventService
        .update(
          event,
          isChecklistActivatedInEditMode,
          this.context,
          this.newDuplicateToOtherModuleValue,
          this.oldDuplicateToOtherModuleValue,
        )
        .then(() => {
          // activate checklist checked in edit mode
          if (isChecklistActivatedInEditMode) {
            this.eventService.sendNewEventInfoChecklistActivatedNotifications(this.event, this.event.infos);
          }
        })
        .finally(() => {
          this.loading = false;
          event.updateACL();
          this.dialogRef.close(event);
        });
    } else {
      this.dialogRef.close(event);
    }
    Promise.all(this.updateGroupSelected())
      .then(() => {
        this.groupService.fetchNewDataEventGroup();
      })
      .catch(error => console.error('Error creating OCLGroup', error))
      .finally(() => {
        this.loading = false;
      });
  }

  createEvent() {
    this.loading = true;

    const event = cloneDeep(this.initialEvent);
    event.description = this.form.get('description').value;
    event.scenario = this.form.get('scenario').value;
    event.attachments = this.attachments;
    event.tags = this.selectedTags;
    event.checklistActivated = this.form.get('hasToActivateCheckLists').value;

    this.eventService
      .create(
        event,
        this.nextInfos,
        this.notifications,
        this.hasToActivateECL,
        this.eclInfos,
        this.eclName,
        this.context,
        this.duplicateToOtherModule,
      )
      .then(newEvent => {
        const infoPromises = [];
        for (let i = this.nextInfos.length - 1; i >= 0; i--) {
          const tempInfo = this.nextInfos[i];
          infoPromises.push(
            this.eventService.addInfo(
              newEvent,
              tempInfo.info.message,
              tempInfo.info.nextInfoTime,
              tempInfo.notifications,
              tempInfo.info.attachments,
            ),
          );
        }
        Promise.all(infoPromises)
          .then(() => {
            this.occEventsStoreManager.addOneEvent(newEvent);
            this.dialogRef.close(newEvent);
            this.updateGroupSelected(newEvent);
          })
          .catch(error => console.error('Error creating OCCEventInfo', error))
          .finally(() => {
            this.groupService.fetchNewDataEventGroup().then();
            this.loading = false;
          });
      })
      .catch(error => {
        if (error && error.message) {
          this.dialog.open(AlertModalComponent, {
            data: {
              modalTitle: 'Error creating event',
              modalContent: error.message,
              modalType: 'info',
            },
          });
        }
        console.error('Error creating OCCEvents', error);
      })
      .finally(() => (this.loading = false));
  }

  openAddNextInfoModal() {
    const dialogRef = this.dialogService.openAddNextInfo({
      data: {
        title: this.getNextInfoTitle(),
        context: {
          module: this.moduleConfig.config.moduleName,
          category: 'NEXT_INFO',
          htmlTitle: this.form.controls.description.value,
          htmlScenario: this.getScenario(),
          htmlDate: this.initialEvent.createdAt,
          htmlTemplate: 'C',
        },
        isUtc: this.isUtc,
        notifications: this.isCreate ? [] : this.notifications,
        defaultNextInfoDelay: this.optionsService.getDefaultNextInfoDelay(),
        acl: cloneDeep(this.initialEvent.acl),
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(tempInfo => {
        if (tempInfo) {
          this.infoHasBeenAdded = true;
          if (this.isCreate) {
            this.nextInfos.unshift(tempInfo);
          } else {
            if (tempInfo) {
              if (this.initialEvent.fromMCC) {
                this.addMCCNextInfo(tempInfo);
                // } else if (this.initialEvent.fromGOC) {
                // this.addGOCNextInfo(tempInfo);
              } else {
                this.addNextInfo(tempInfo);
              }
            }
          }
          this.cdr.detectChanges();
        }
      });
  }

  openDetailsModal($event: MouseEvent, item: HolNextInfo) {
    this.dialogService
      .openDetailNextInfo({
        data: {
          info: item,
          title: this.getNextInfoTitle(),
          context: {
            module: this.moduleConfig.config.translateKey,
            category: 'NEXT_INFO',
            htmlTitle: this.form.controls.description.value,
            htmlScenario: this.getScenario(),
            htmlDate: this.initialEvent.createdAt,
            htmlTemplate: 'C',
          },
          isUtc: this.isUtc,
          isReadOnly: this.isReadOnly || this.fromOtherUnivers,
          canEditNi: this.moduleConfig.config.canEditNi,
        },
      })
      .afterClosed()
      .toPromise()
      .then(info => {
        if (info) {
          this.loading = true;
          this.eventService
            .updateInfo(info)
            .then(newInfo => {
              Object.assign(item, newInfo);
            })
            .finally(() => {
              this.loading = false;
              this.cdr.detectChanges();
            });
        }
      });
  }

  markAsDone(item) {
    this.eventService.markInfoAsDone(item, this.initialEvent, !item.done, true).then(eventInfo => {
      extend(item, eventInfo);
      this.flightsStatusService.setNeedsUpdate(true);
    });
  }

  public getScenario() {
    if (this.initialEvent && this.initialEvent.scenario) {
      return this.initialEvent.scenario.code + this.initialEvent.order + ' - ' + this.initialEvent.scenario.title;
    }
    if (this.form.controls.scenario && this.form.controls.scenario.value) {
      return this.form.controls.scenario.value.code + ' - ' + this.form.controls.scenario.value.title;
    }
    return '';
  }

  archive() {
    this.dialog
      .open(OclEventCloseModalComponent, {
        data: {
          event: this.initialEvent,
          notifications: this.notifications,
          hasToDeactivateECL: this.eclInfos && this.eclInfos.occEventId === this.initialEvent.objectId,
          eclOptionsId: this.eclInfos ? this.eclInfos.eclOptionsId : undefined,
        },
      })
      .afterClosed()
      .toPromise()
      .then(reason => {
        if (reason !== undefined && reason !== '') {
          this.dialogRef.close(reason);
        }
      });
  }

  private addNextInfo(tempInfo) {
    this.eventService
      .addInfo(this.initialEvent, tempInfo.info.message, tempInfo.info.nextInfoTime, tempInfo.notifications, tempInfo.info.attachments)
      .then(newInfo => {
        this.nextInfos.unshift({ info: newInfo, notifications: null });
        this.flightsStatusService.setNeedsUpdate(true);
        this.cdr.detectChanges();
      })
      .catch(error => console.error('Add OCC next info failed', error));
  }

  private addMCCNextInfo(tempInfo) {
    this.mccEventsService
      .addInfo(
        this.initialEvent as any,
        tempInfo.info.message,
        tempInfo.info.nextInfoTime,
        tempInfo.notifications,
        tempInfo.info.attachments,
        null,
      )
      .then(() => {
        this.nextInfos = this.initialEvent.infos
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map(i => {
            return { info: i, notifications: null };
          });
        this.cdr.detectChanges();
      })
      .catch(error => console.error('Add MCC next info failed', error));
  }

  private getNextInfoTitle() {
    let title;
    const scenario = this.form.get('scenario').value;
    if (this.initialEvent && this.initialEvent.scenario) {
      title = `${this.initialEvent.scenario.code || ''}${this.initialEvent.order || ''} - ${this.initialEvent.scenario.title}`;
    } else if (scenario) {
      title = `${scenario.code} - ${scenario.title}`;
    } else {
      title = 'NEXT INFO';
    }
    return title;
  }

  isApplicabilityChange(): boolean {
    return false;
  }

  isDuplicateChange(): boolean {
    return !isEqual(this.copyToGOC, this.initialEvent.toGOC) || !isEqual(this.copyFromGOC, this.initialEvent.fromGOC);
  }

  deleteGocEvent(): void {
    this.eventService.deleteDuplicateGocEvent(this.initialEvent);
    this.dialogRef.close();
  }

  saveNotifications(notifications: HolNotification[]) {
    this.notifications = cloneDeep(notifications);
  }

  ngOnDestroy(): void {
    this.commonStoreManagerSub.unsubscribe();
  }

  get limitTo(): HolObject | undefined {
    return this.form.get('scenario').value;
  }

  public updateLinkedGroup(e) {
    this.linkedGroup = e;
  }

  isNoteSave($event: boolean) {
    this.isNoteSaved = $event;
  }

  protected updateGroupSelected(event?: OclEvent): Promise<any>[] {
    const listGroupPromise = [];

    // First we remove this item from all groups & then we add the item to the list of groups.
    this.eventGroup.forEach(lgr => {
      if (this.data.event) lgr.items = lgr.items.filter(lg => lg.objectId !== this.data.event.objectId);
      if (this.linkedGroup.includes(lgr.objectId)) {
        if (event) {
          lgr.items.push(event);
        } else {
          lgr.items.push(this.data.event);
        }
      }
      listGroupPromise.push(this.groupService.save(lgr, 'EVENT'));
    });

    return listGroupPromise;
  }
}
