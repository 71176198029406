'use strict';

angular
  .module('gestiondecriseApp')
  .controller('CrewChecklistCtrl', function ($q, $scope, CrewEventsService, $mdDialog, CrewFunctionsService, UserService, $rootScope) {
    $scope.loading = false;
    $scope.events = [];
    $scope.currentFilter = null;

    function init() {
      $scope.loading = true;
      CrewEventsService.getAll($scope.currentFilter)
        .then(function (events) {
          $scope.events = events;
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    var unregisterWatchFilters = $scope.$watch('currentFilter', init);
    var unregisterSituationEvent = $scope.$on('crew-situation-updated', init);
    var unregisterPoolService = $scope.$on('crewPoolService-events', function (e, events) {
      $scope.events = events;
    });

    $scope.getNbAccepted = function (event) {
      return CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED');
    };

    $scope.getNbWaiting = function (event) {
      return CrewEventsService.getNbUserBySituationStatus(event, 'WAITING');
    };

    $scope.getNbRefused = function (event) {
      return CrewEventsService.getNbUserBySituationStatus(event, 'REFUSED');
    };

    $scope.openEventModal = function ($event, event) {
      $mdDialog
        .show({
          controller: 'CrewEventModalCtrl',
          templateUrl: 'views/crew/modals/event.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            functions: CrewFunctionsService.getAll,
            event: event,
            isReadOnly: $scope.$root.isReadOnly
          }
        })
        .catch(function (error) {
          console.error("Erreur lors de l'ouverture de la modale : ", error);
        })
        .then(function (retVal) {
          if (retVal) {
            init();
          }
        });
    };

    $scope.getUsersOrder = function (a, b) {
      function getValue(user) {
        if (!user.lastSituation) {
          return 10;
        }
        if (user.lastSituation.status === 'NEUTRAL') {
          return 9;
        }
        if (user.lastSituation.status === 'WAITING') {
          return 8;
        }
        if (user.lastSituation.status === 'ACCEPTED') {
          return 7;
        }
        if (user.lastSituation.status === 'REFUSED') {
          return 6;
        }
      }

      var valA = getValue(a.value);
      var valB = getValue(b.value);
      if (valA !== valB) {
        return valB - valA;
      } else if (valA === 10 && valB === 10) {
        return a.value.trigram < b.value.trigram ? -1 : 1;
      } else {
        return moment(a.value.lastSituation.createdAt).isBefore(b.value.lastSituation.createdAt) ? -1 : 1;
      }
    };

    $scope.openUserModal = function ($event, user, event) {
      $mdDialog.show({
        controller: 'CrewUserModalCtrl',
        templateUrl: 'views/crew/modals/user.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          user: user,
          event: event,
          situation: user.lastSituation,
          isReadOnly: $scope.$root.isReadOnly,
          isArchivesOpened: false
        }
      });
    };

    $scope.openCloseEventModal = function ($event, event) {
      $mdDialog
        .show({
          templateUrl: 'views/crew/modals/event-close.modal.html',
          controller: 'CrewEventCloseModalCtrl',
          clickOutsideToClose: true,
          targetEvent: $event,
          locals: {
            event: event
          }
        })
        .then(function () {
          $mdDialog.hide();
        });
    };

    $scope.isInFunction = function (event) {
      return !$rootScope.crewSelectedFunction || (event.function && event.function.code === $rootScope.crewSelectedFunction);
    };

    $scope.notInFunction = function (user, event) {
      return event.function && (!user.functions || user.functions.indexOf(event.function.code) === -1);
    };

    $scope.getUserInitials = UserService.getUserInitials;

    $scope.$on('$destroy', function () {
      unregisterWatchFilters();
      unregisterSituationEvent();
      unregisterPoolService();
    });
  });
