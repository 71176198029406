import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclSummary } from '../../models/ecl-summary';
import { take } from 'rxjs/operators';
import { EclAssetService } from '../ecl-asset-service/ecl-asset.service';
import { orderBy, sum } from 'lodash';
import { EclLogbook } from '../../models/ecl-logbook';
import { EclDecision } from '../../models/ecl-decision';
import { EclAsset } from '../../models/ecl-asset';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { EclDecisionGroup } from '../../models/ecl-decision-group.model';
import { EclLogbookGroup } from '../../models/ecl-logbook-group.model';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { OclGroup } from '../../../ocl/models/ocl-group.model';

@Injectable({
  providedIn: 'root',
})
export class EclSummaryService {
  private parseAsset = Parse.Object.extend('ECLAsset');
  private parseAssetType = Parse.Object.extend('ECLAssetType');
  private parseAssetParameter = Parse.Object.extend('ECLAssetParameter');
  private parseLogBook = Parse.Object.extend('ECLLogBook');
  private parseDecisions = Parse.Object.extend('ECLDecisions');
  private parseCrisis = Parse.Object.extend('ECLCrisis');
  private eclOpenCrisis: EclCrisis;

  constructor(
    protected requestService: RequestService,
    protected eclCrisisStoreManager: EclCrisisStoreManager,
    protected eclAssetService: EclAssetService,
    private store: Store<AppState>,
    private oclLogBooksStoreManager: OclLogBooksStoreManager,
    private oclDecisionStoreManager: OclDecisionsStoreManager,
    private oclGroupStoreManager: OclGroupsStoreManager,
  ) {
    this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(crisis => (this.eclOpenCrisis = crisis));
  }

  async getAllSummaryForCurrentCrisis(onlyInBreifing = false): Promise<EclSummary[]> {
    return this.getSummariesFunc(onlyInBreifing, this.eclOpenCrisis);
  }

  async getAllSummaryForAllCrisis(): Promise<EclSummary[]> {
    return this.getSummariesFunc(false, null);
  }

  async reOrderSummary(summaries: EclSummary[]) {
    summaries.forEach((summary, index) => {
      summary.order = index;
    });
    this.eclCrisisStoreManager.updateManySummary(summaries);
    this.requestService.performSaveAllQuery(summaries.map(value => value.parseToObject())).then(
      () => {},
      error => {
        console.error('Error while saving summaries', error);
      },
    );
  }

  async fetchNewData(): Promise<void> {
    const buffer = await this.getAllSummaryForAllCrisis();
    this.eclCrisisStoreManager.initEclSummaryListFromDashboardPolling(buffer);
  }

  async deleteSummary(summary: EclSummary) {
    await this.requestService.performDestroyQuery(summary.parseToObject());
    this.eclCrisisStoreManager.deleteOneSummary(summary);
  }

  async saveNewSummaryFromLogbookOrDecision(
    object: EclDecision | EclLogbook | OclGroup<any>,
    type: 'LOGBOOK' | 'DECISION' | 'GROUP_LOGBOOK' | 'GROUP_DECISION',
    crisis: EclCrisis,
    isInBriefing: boolean,
    isInSummary?: boolean,
  ) {
    if (object.summary && object.summary.objectId) {
      if (!isInSummary) {
        await this.requestService.performDestroyQuery(object.summary.parseToObject());
        this.eclCrisisStoreManager.deleteOneSummary(object.summary);

        if (type === 'LOGBOOK') {
          (object as EclLogbook).summary = null;
          this.oclLogBooksStoreManager.updateOneLogBook(object as EclLogbook);
        } else if (type === 'DECISION') {
          (object as EclDecision).summary = null;
          this.oclDecisionStoreManager.updateOneDecision(object as EclDecision);
        } else if (type === 'GROUP_LOGBOOK') {
          (object as EclLogbookGroup).summary = null;
          this.oclGroupStoreManager.updateOneGroup(object as EclLogbookGroup, 'logbooks', 'ecl');
        } else if (type === 'GROUP_DECISION') {
          (object as EclDecisionGroup).summary = null;
          this.oclGroupStoreManager.updateOneGroup(object as EclDecisionGroup, 'decisions', 'ecl');
        }
        return;
      } else {
        if (isInSummary && object.summary.isDisplayInBriefing !== isInBriefing) {
          object.summary.isDisplayInBriefing = isInBriefing;

          const updatedSummary = await this.updateSummary(object.summary);
          this.eclCrisisStoreManager.upadteOneSummary(updatedSummary);
        } else {
          let summary: EclSummary;

          const listSummariesStored = await this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise();

          if (type == 'LOGBOOK') {
            summary = listSummariesStored.find(value => value.logBook && value.logBook.objectId == object.objectId);
            summary.logBook = object as EclLogbook;
          } else if (type == 'DECISION') {
            summary = listSummariesStored.find(value => value.decision && value.decision.objectId == object.objectId);
            summary.decision = object as EclDecision;
            //  summary = await this.getSummaryByDecision(object as EclDecision);
          } else if (type === 'GROUP_LOGBOOK') {
            summary = listSummariesStored.find(value => value.logBookGroup && value.logBookGroup.objectId == object.objectId);
            //  (object as EclLogbookGroup).summary = null;
            //   this.oclGroupStoreManager.updateOneGroup((object as EclLogbookGroup),"logbooks","ecl");
            summary.logBookGroup = object as EclLogbookGroup;
          } else if (type === 'GROUP_DECISION') {
            summary = listSummariesStored.find(value => value.decisionGroup && value.decisionGroup.objectId == object.objectId);
            summary.decisionGroup = object as EclDecisionGroup;
            //  (object as EclDecisionGroup).summary = null;
            //  this.oclGroupStoreManager.updateOneGroup((object as EclDecisionGroup),"decisions","ecl");
          }
          try {
            summary.loadDisplayData();
          } catch (e) {}

          this.eclCrisisStoreManager.upadteOneSummary(summary);
        }
      }
    } else {
      if (isInSummary) {
        const currentSummaries = this.getValue(this.store.select('ecl', 'crisisState', 'summaries'));
        const orderedSummary = orderBy(
          currentSummaries.filter(value => value.crisis.objectId === crisis.objectId),
          ['order'],
          ['desc'],
        );

        const summary = new EclSummary();
        summary.order = orderedSummary && orderedSummary[0] && orderedSummary[0].order ? orderedSummary[0].order + 1 : 0;

        summary.logBook = type === 'LOGBOOK' ? (object as EclLogbook) : undefined;
        summary.decision = type === 'DECISION' ? (object as EclDecision) : undefined;
        summary.logBookGroup = type === 'GROUP_LOGBOOK' ? (object as EclLogbookGroup) : undefined;
        summary.decisionGroup = type === 'GROUP_DECISION' ? (object as EclDecisionGroup) : undefined;
        summary.crisis = crisis;
        summary.isDisplayInBriefing = isInBriefing;

        const newSummaryParse = await this.requestService.performSaveQuery(summary.parseToObject());
        const newSummary = new EclSummary(newSummaryParse);
        this.eclCrisisStoreManager.createOneSummary(newSummary);
      }
    }
  }

  async saveGroupInSummary(
    object: EclDecisionGroup | EclLogbookGroup,
    type: 'LOGBOOK_GROUP' | 'DECISION_GROUP',
    crisis: EclCrisis,
    isInBriefing: boolean,
    isInSummary?: boolean,
  ) {
    //fixme how to check if is in summary already ?
    /*if (object.summary && object.summary.objectId) {
      if (!isInSummary) {
        await this.requestService.performDestroyQuery(object.summary.parseToObject());
        this.eclCrisisStoreManager.deleteOneSummary(object.summary);

        if (type === 'LOGBOOK') {
          (object as EclLogbook).summary = null;
          this.oclLogBooksStoreManager.updateOneLogBook(object as EclLogbook);
        } else if (type === 'DECISION') {
          (object as EclDecision).summary = null;
          this.oclDecisionStoreManager.updateOneDecision(object as EclDecision);
        }
        return;
      } else {
        if (isInSummary && object.summary.isDisplayInBriefing !== isInBriefing) {
          object.summary.isDisplayInBriefing = isInBriefing;
          const updatedSummay = await this.updateSummary(object.summary);
          this.eclCrisisStoreManager.upadteOneSummary(updatedSummay);
        } else {
          let summary: EclSummary;

          if (type == 'LOGBOOK') {
            summary = await this.getSummaryByLogBook(object as EclLogbook);
          } else if (type == 'DECISION') {
            summary = await this.getSummaryByDecision(object as EclDecision);
          }
          this.eclCrisisStoreManager.upadteOneSummary(summary);
        }
      }
    } else {
      if (isInSummary) {
        const currentSummaries = this.getValue(this.store.select('ecl', 'crisisState', 'summaries'));
        const orderedSummary = orderBy(currentSummaries, ['order'], ['desc']);

        const summary = new EclSummary();
        summary.order = orderedSummary && orderedSummary[0] && orderedSummary[0].order ? orderedSummary[0].order + 1 : 0;

        summary.logBook = type === 'LOGBOOK' ? (object as EclLogbook) : undefined;
        summary.decision = type === 'DECISION' ? (object as EclDecision) : undefined;
        summary.crisis = crisis;
        summary.isDisplayInBriefing = isInBriefing;

        const newSummaryParse = await this.requestService.performSaveQuery(summary.parseToObject());
        const newSummary = new EclSummary(newSummaryParse);
        this.eclCrisisStoreManager.createOneSummary(newSummary);
      }
    }*/
    //
  }

  async getSpecificSummary(id: string): Promise<EclSummary> {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);
    mainQuery.equalTo('objectId', id);
    mainQuery.includeAll();
    const parseSummaries = await this.requestService.performFirstQuery(mainQuery);
    const summary = new EclSummary(parseSummaries);
    if (summary.asset) {
      const params = await this.eclAssetService.getAssetParamatersByAsset(summary.asset);
      summary.asset.parameters = params;
    }
    return summary;
  }

  async getSummaryByAsset(asset: EclAsset) {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);

    const innerCrisisQuery = new Parse.Query(this.parseAsset);
    innerCrisisQuery.equalTo('objectId', asset.objectId);
    innerCrisisQuery.includeAll();
    mainQuery.matchesQuery('asset', innerCrisisQuery);
    mainQuery.includeAll();
    const parseSummaries = await this.requestService.performFirstQuery(mainQuery);
    const summary = new EclSummary(parseSummaries);
    if (summary.asset) {
      const params = await this.eclAssetService.getAssetParamatersByAsset(summary.asset);
      summary.asset.parameters = params;
    }
    return summary;
  }

  async getSummaryByLogBook(logbook: EclLogbook) {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);

    const innerCrisisQuery = new Parse.Query(this.parseLogBook);
    innerCrisisQuery.equalTo('objectId', logbook.objectId);
    mainQuery.matchesQuery('logbook', innerCrisisQuery);
    mainQuery.includeAll();

    //TODO check if is exist
    const parseSummaries = await this.requestService.performFirstQuery(mainQuery);
    return new EclSummary(parseSummaries);
  }

  async getSummaryByDecision(decision: EclDecision) {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);

    const innerCrisisQuery = new Parse.Query(this.parseDecisions);
    innerCrisisQuery.equalTo('objectId', decision.objectId);
    mainQuery.matchesQuery('decision', innerCrisisQuery);
    mainQuery.includeAll();
    //TODO check if is exist
    const parseSummaries = await this.requestService.performFirstQuery(mainQuery);

    return new EclSummary(parseSummaries);
  }

  async updateSummary(summary: EclSummary): Promise<EclSummary> {
    //fixme multiple clicks on toggle briefing in synthesis will trigger TypeError: summary.parseToObject is not a function on .parseToObject() below
    /*
    if (typeof summary.parseToObject !== 'function') {
      throw new Error('The summary object does not have the parseToObject method.');
    }
    */
    if (summary && typeof summary.parseToObject === 'function') {
      const updatedSummary = await this.requestService.performSaveQuery(summary.parseToObject());
      const newEclSummary = new EclSummary(updatedSummary);
      if (summary.asset) {
        if (summary.asset.parameters) {
          newEclSummary.asset.parameters = summary.asset.parameters;
        }
      }
      return newEclSummary;
    } else {
      return summary;
    }
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  async anySummaryForGroup(group: EclLogbookGroup | EclDecisionGroup): Promise<EclSummary> | null {
    const summaries = await this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise();
    //   const summaries: EclSummary[] = await this.getAllSummaryForAllCrisis();

    if (group instanceof EclLogbookGroup) {
      return summaries.find(summary => summary.logBookGroup && summary.logBookGroup.objectId === group.objectId);
    }

    if (group instanceof EclDecisionGroup) {
      return summaries.find(summary => summary.decisionGroup && summary.decisionGroup.objectId === group.objectId);
    }

    return null;
  }

  private async getSummariesFunc(onlyInBreifing = false, crisis: EclCrisis | null): Promise<EclSummary[]> {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);

    if (crisis) {
      const innerCrisisQuery = new Parse.Query(this.parseCrisis);
      innerCrisisQuery.equalTo('objectId', crisis.objectId);
      innerCrisisQuery.includeAll();
      mainQuery.matchesQuery('crisis', innerCrisisQuery);
    }

    if (onlyInBreifing) {
      mainQuery.equalTo('shouldDisplayInBriefing', true);
    }
    mainQuery.includeAll();
    mainQuery.ascending('briefingOrder');

    const parseSummaries = await this.requestService.performFindAllQuery(mainQuery);
    const summaries = parseSummaries
      .map(summary => new EclSummary(summary))
      .filter(summary => summary.asset || summary.logBook || summary.decision || summary.logBookGroup || summary.decisionGroup);

    const allParamsNeeded = await this.eclAssetService.getAssetParameters(
      summaries.filter(summary => summary.asset).map(summary => summary.asset.objectId),
    );

    summaries.forEach(summary => {
      if (summary.asset) {
        const params = allParamsNeeded.get(summary.asset.objectId);
        if (params) {
          summary.asset.parameters.push(...params);
        }
      }
    });

    return summaries;
  }

  async transfertDecisionToLogbook(decisionId: string, logbookID: string) {
    const summariesId: SummariesToUpdateSimple[] = [];
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);
    const innerCrisisQuery = new Parse.Query(this.parseDecisions);
    innerCrisisQuery.equalTo('objectId', decisionId);
    mainQuery.matchesQuery('decision', innerCrisisQuery);
    mainQuery.includeAll();

    mainQuery
      .find()
      .then(results => {
        results.forEach(crisisSummary => {
          summariesId.push({
            summaryId: crisisSummary.id,
            decisionId: decisionId,
            logbookId: logbookID,
            decisionToLogbook: true,
          });
          crisisSummary.unset('decision');
          crisisSummary.set('logbook', new this.parseLogBook({ id: logbookID }));
        });
        this.updateStoreWithoutPolling(summariesId);
        return Parse.Object.saveAll(results);
      })
      .then(() => {
        return summariesId;
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des pointeurs : ', error);
        return [];
      });
  }

  async transfertLogbookToDecision(logbookID: string, decisionId: string) {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);
    const innerCrisisQuery = new Parse.Query(this.parseLogBook);
    innerCrisisQuery.equalTo('objectId', logbookID);
    mainQuery.matchesQuery('logbook', innerCrisisQuery);
    mainQuery.includeAll();

    const summariesId: SummariesToUpdateSimple[] = [];
    mainQuery
      .find()
      .then(results => {
        results.forEach(crisisSummary => {
          summariesId.push({
            summaryId: crisisSummary.id,
            decisionId: decisionId,
            logbookId: logbookID,
            decisionToLogbook: false,
          });
          crisisSummary.unset('logbook');
          crisisSummary.set('decision', new this.parseDecisions({ id: decisionId }));
        });
        this.updateStoreWithoutPolling(summariesId);
        return Parse.Object.saveAll(results);
      })
      .then(() => {
        return summariesId;
      })
      .catch(error => {
        console.error('Erreur lors de la suppression des pointeurs : ', error);
        return [];
      });
  }

  private async updateStoreWithoutPolling(summariesToUpdate: SummariesToUpdateSimple[]) {
    // this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise();

    const summaries = await this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise();
    const logbooks = await this.oclLogBooksStoreManager.logbookList.pipe(take(1)).toPromise();
    const decisions = await this.oclDecisionStoreManager.decisionList.pipe(take(1)).toPromise();

    combineLatest([
      this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise(),
      this.oclLogBooksStoreManager.logbookList.pipe(take(1)).toPromise(),
      this.oclDecisionStoreManager.decisionList.pipe(take(1)).toPromise(),
    ]).subscribe(([summaries, logbooks, decisions]) => {
      summaries = summaries.filter(value => {
        return summariesToUpdate.find(val => val.summaryId === value.objectId);
      });

      const summaryStore: EclSummary[] = [];
      summariesToUpdate.forEach(value => {
        const summary = summaries.find(val => val.objectId === value.summaryId);
        if (summary) {
          if (value.decisionToLogbook) {
            summary.decision = null;
            summary.logBook = logbooks.find(val => val.objectId === value.logbookId) as EclLogbook;
          } else {
            summary.logBook = null;
            summary.decision = decisions.find(val => val.objectId === value.decisionId) as EclDecision;
          }
          summary.loadDisplayData();
          summaryStore.push(summary);
        }
      });

      this.eclCrisisStoreManager.updateManySummary(summaryStore);
    });
  }
}

type SummariesToUpdateSimple = {
  summaryId: string;
  decisionId: string;
  logbookId: string;
  decisionToLogbook: boolean;
};
