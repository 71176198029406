<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      {{ managerFunction.title }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <div *ngFor="let company of managerFunction.companies">
        <mat-form-field class="full-width">
          <mat-label>
            <app-acl-indicator [object]="{ companies: [company.name], companiesObj: [] }"></app-acl-indicator>
            &nbsp;{{ company.name }}&nbsp;-&nbsp;
            {{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.CHOOSE_MEMBER' | translate }}
          </mat-label>
          <mat-select [(ngModel)]="company.holder" [disabled]="company.users[0].readOnly">
            <mat-option>{{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.NOBODY_OPTION' | translate }}</mat-option>
            <mat-option *ngFor="let user of company.users" [value]="user">
              {{ user.user?.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="company.holder" class="hol-dialog-section inputs-date">
          <label
            ><strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.EXPIRATION_DATE' | translate }}</strong></label
          >
          <app-hol-next-info
            [isReadOnly]="company.users[0].readOnly"
            [nextInfo]="company.holder.expiredAtNextInfo"
            [hasDoneBtn]="false"
            (saveNextInfo)="company.holder.expiredAtNextInfo = $event"
          ></app-hol-next-info>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="action-bar">
      <div class="spacer"></div>
      <button class="action action-validate" (click)="validate()">
        <mat-icon>done</mat-icon>
        <span>{{ 'DASHBOARD.MODAL.SUBMIT' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
