import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserService } from 'src/app/common/services/user.service';
import { cloneDeep, join } from 'lodash';
import * as moment from 'moment';
import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';

import { HolFunction } from '../../../common/models/hol-function';
import { HolManagersService } from '../../../common/services/hol-managers.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { VacationService } from '../../../common/services/vacation.service';
import { HolManagerFunction } from '../../../common/store/hol-managers.selectors';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';

@Component({
  selector: 'app-ocl-managers-modal',
  templateUrl: './ocl-managers-modal.component.html',
  styleUrls: ['./ocl-managers-modal.component.scss'],
})
export class OclManagersModalComponent {
  managerFunction: HolManagerFunction;
  holFunctions: HolFunction[];
  loading = false;

  constructor(
    private managersService: HolManagersService,
    private vacationService: VacationService,
    private readonly mailSenderService: MailSenderService,
    private translate: TranslatePipe,
    @Inject(MAT_DIALOG_DATA) private data: { managerFunction: HolManagerFunction; holFunctions: HolFunction[] },
    public dialogRef: MatDialogRef<OclManagersModalComponent>,
    public moduleConfig: ModuleConfigService,
    private readonly userService: UserService,
  ) {
    this.managerFunction = cloneDeep(data.managerFunction);
    this.holFunctions = cloneDeep(data.holFunctions);
  }

  async validate() {
    this.loading = true;
    const prevManagerFunction: HolManagerFunction = this.data.managerFunction;
    try {
      const [holUserFunctions, allUsers] = await Promise.all([
        this.managersService.updateManagerFunction(this.managerFunction, prevManagerFunction),
        this.userService.getAllUsers(),
      ]);
      if (!holUserFunctions || !allUsers) {
        this.dialogRef.close();
      }
      if (holUserFunctions && holUserFunctions.length) {
        holUserFunctions.forEach(holUserFunction => {
          if (holUserFunction.isHolder) {
            const user = allUsers.find(u => u.userId === holUserFunction.userId);
            const func = this.holFunctions.find(f => f.functionId === holUserFunction.functionId);
            this.vacationService.closePreviousVacations(holUserFunction, func).then(() => {
              this.vacationService.getVacationREFByFunction(holUserFunction.functionId).then(vacationsByFunction => {
                if (vacationsByFunction) {
                  this.vacationService
                    .createVacation(vacationsByFunction, holUserFunction, user, func, this.moduleConfig.config.moduleName)
                    .finally(() => {
                      this.managerFunction.companies.forEach((company, i) => {
                        const prevCompany = prevManagerFunction.companies[i];
                        if (
                          company.holder &&
                          (!prevCompany.holder ||
                            company.holder.objectId !== prevCompany.holder.objectId ||
                            !moment(company.holder.expiredAtNextInfo.nextInfoTime).isSame(
                              prevCompany.holder.expiredAtNextInfo.nextInfoTime,
                            ))
                        ) {
                          const recipients = [];
                          if (this.managerFunction.email) {
                            recipients.push({ email: this.managerFunction.email });
                          }
                          if (company.holder.user && company.holder.user.email) {
                            recipients.push({ email: company.holder.user.email });
                          }
                          this.mailSenderService.sendMail(
                            {
                              recipients,
                              subject: this.translate.transform('MAIL.VACATION.SUBJECT', {
                                function: this.managerFunction.title,
                                user: company.holder.user.fullName,
                                company: company.name,
                              }),
                              contentHtml: join(
                                [
                                  this.translate.transform('MAIL.VACATION.BODY', {
                                    function: this.managerFunction.title,
                                    user: company.holder.user.fullName,
                                    company: company.name,
                                  }),
                                ],
                                '<br/><br/>',
                              ),
                            },
                            true,
                            true,
                          );
                        }
                      });
                      this.loading = false;
                      this.dialogRef.close();
                    });
                }
              });
            });
          }
          this.dialogRef.close();
        });
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
    }
  }
}
